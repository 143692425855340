export enum NotificationEvent {
    // Purchase-related events
    ITEM_PURCHASED = 1, // To Seller, when their item(s) are purchased.
    ITEM_TRANSFERRED = 14, // To Buyer, when an item is transferred.

    // Admin-related events
    BLOCKED = 2, // Blocked by admin.
    UNBLOCKED = 3, // Unblocked by admin.

    // Loan-related events
    LENDING_AMOUNT_REPAID = 4, // To Lender, when lending amount is repaid by the borrower.
    LOAN_FORECLOSED = 5, // To Borrower, when the loan is foreclosed.
    LENDER_ACCEPTED_LOAN_REQUEST = 6, // To Borrower, when lender accepts the loan request.
    LENDER_COUNTER_OFFER = 7, // To Borrower, when a lender gives a counter offer.
    BORROWER_ACCEPT_COUNTER = 8, // To Lender, when a borrower accepts the counter offer.
    BORROWER_RECOUNTER_OFFER = 9, // To Lender, when a borrower gives a re-counter offer.
    LENDER_ACCEPT_RECOUNTER = 10, // To Borrower, when a lender accepts the re-counter offer.
    LENDER_RECOUNTER_OFFER = 12, // To Borrower, when a lender gives a re-counter offer.
    BORROWER_ACCEPT_RECOUNTER = 13, // To Lender, when a borrower accepts the re-counter offer.
    FORECLOSE_SUCCESS = 29, // To Sender, when the loan is successfully foreclosed.
    LOAN_DEFAULTED = 30, // To Lender, when the loan is defaulted.
    LOAN_DEFAULTED_NOTIFICATION_TO_BORROWER = 31, // To Borrower, when their loan is defaulted.
    LOAN_OFFER_RECEIVED = 32, // To Lender, when a new loan offer is received.
    LOAN_OFFER_CREATED = 33, // To Borrower, when a lender creates a loan offer.
    REVISED_LOAN_BID_RECEIVED = 34, // To Lender, when a revised loan bid is received.
    LOAN_BID_APPROVED = 35, // To Lender, when their loan bid is approved.
    LOAN_BID_APPROVED_NOTIFICATION_TO_OWNER = 36, // To Owner, when they approve a loan bid.
    LOAN_BID_CANCELLED = 37, // To Lender, when a borrower cancels their loan bid.

    // Bid-related events
    BID_RECEIVED = 17, // To Seller/Borrower, when a bid is received.
    REVISED_BID_RECEIVED = 18, // To Seller, when a revised bid is received.
    BID_CANCELLED = 19, // To Buyer, when their bid is cancelled.
    BID_APPROVED = 20, // To Buyer, when their bid is approved.
    BID_APPROVED_NOTIFICATION_TO_SELLER = 21, // To Seller, when they approve a bid.
    BID_SUBMITTED = 22, // To Buyer, when they submit a bid.
    REVISED_BID_SUBMITTED = 23, // To Buyer, when their bid is revised.
    BID_CANCELLED_NOTIFICATION_TO_SELLER = 24, // To Seller, when they cancel a bid.
    BID_RECEIVED_FOR_FORECLOSED_NFT = 38, // To Seller/Lender, when a bid is received for a foreclosed NFT.

    // Delivery-related events
    DELIVERY_REQUEST_SENT = 25, // To User, when they send a delivery request.
    DELIVERY_REQUEST_RECEIVED = 26, // To Admin, when a delivery request is received.
    DELIVERY_REQUEST_UPDATED = 27, // To Admin, when a delivery request is updated.
    DELIVERY_REQUEST_CANCELLED = 28, // To Admin, when a delivery request is cancelled.
    DELIVERY_REQUEST_CANCELLED_USER = 39, // To User, when a delivery request is cancelled.

    // Reminder-related events
    REMINDER_70_PERCENT_DUE = 11, // Reminder for 70% due.
    REMINDER_24_HOURS_DUE = 15, // Reminder for 24 hours due.
    REMINDER_90_PERCENT_DUE = 16, // Reminder for 90% due.
}

export const ADMIN_NOTIFICATIONS = [
    NotificationEvent.BLOCKED, //2
    NotificationEvent.UNBLOCKED, //3
];

export const LENDER_NOTIFICATIONS = [
    NotificationEvent.LENDING_AMOUNT_REPAID, //4
    NotificationEvent.BORROWER_ACCEPT_COUNTER, //8
    NotificationEvent.BORROWER_RECOUNTER_OFFER, //9
    NotificationEvent.BORROWER_ACCEPT_RECOUNTER, //13
    NotificationEvent.LOAN_DEFAULTED, //30
    NotificationEvent.LOAN_BID_APPROVED, //35
    NotificationEvent.BID_RECEIVED_FOR_FORECLOSED_NFT //38
];

export const BORROWER_NOTIFICATIONS = [
    NotificationEvent.LOAN_FORECLOSED, //5
    NotificationEvent.LENDER_ACCEPTED_LOAN_REQUEST, //6
    NotificationEvent.LENDER_COUNTER_OFFER, //7
    NotificationEvent.LENDER_ACCEPT_RECOUNTER, //10
    NotificationEvent.REMINDER_70_PERCENT_DUE, //11
    NotificationEvent.LENDER_RECOUNTER_OFFER, //12
    NotificationEvent.REMINDER_24_HOURS_DUE, //15
    NotificationEvent.REMINDER_90_PERCENT_DUE, //16
    NotificationEvent.FORECLOSE_SUCCESS,//29
    NotificationEvent.LOAN_DEFAULTED_NOTIFICATION_TO_BORROWER, //31
    NotificationEvent.LOAN_BID_APPROVED_NOTIFICATION_TO_OWNER //36
];

export const OTHER_NOTIFICATONS = [
    NotificationEvent.ITEM_PURCHASED, //1
    NotificationEvent.ITEM_TRANSFERRED, //14
    NotificationEvent.BID_RECEIVED, //17
    NotificationEvent.REVISED_BID_RECEIVED, //18
    NotificationEvent.BID_CANCELLED, //19
    NotificationEvent.BID_APPROVED, //20
    NotificationEvent.BID_APPROVED_NOTIFICATION_TO_SELLER, //21
    NotificationEvent.BID_SUBMITTED, //22
    NotificationEvent.REVISED_BID_SUBMITTED, //23
    NotificationEvent.BID_CANCELLED_NOTIFICATION_TO_SELLER, //24
    NotificationEvent.DELIVERY_REQUEST_SENT, //25
    NotificationEvent.DELIVERY_REQUEST_RECEIVED,//26
    NotificationEvent.DELIVERY_REQUEST_UPDATED,//27
    NotificationEvent.DELIVERY_REQUEST_CANCELLED_USER,//39
    NotificationEvent.LOAN_OFFER_RECEIVED, //32
    NotificationEvent.REVISED_LOAN_BID_RECEIVED, //34
    NotificationEvent.LOAN_OFFER_CREATED, //33
    NotificationEvent.LOAN_BID_CANCELLED, //37
];

