import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';

import { AuthService } from './shared/services/auth.service';

const routes: Routes = [
    { path: '', loadChildren: () => import('./module/dashboard/dashboard.module').then(m => m.DashboardModule), pathMatch: 'full', canActivate: [authGuard] },
    { path: 'dashboard', loadChildren: () => import('./module/new-dashboard/new-dashboard.module').then(m => m.NewDashboardModule), canActivate: [authGuard] },
    { path: 'my-wallet', loadChildren: () => import('./module/youritems/youritems.module').then(m => m.YouritemsModule), canActivate: [authGuard] },
    { path: 'borrow', loadChildren: () => import('./module/borrow/borrow.module').then(m => m.BorrowModule), canActivate: [authGuard] },
    { path: 'lend', loadChildren: () => import('./module/lend/lend.module').then(m => m.LendModule), canActivate: [authGuard] },
    {
        path: 'sign-in',
        loadChildren: () => import('./module/auth/sign-in/sign-in.module').then(m => m.SignInModule),
        canActivate: [AuthService]
    },
    {
        path: 'sign-up',
        loadChildren: () => import('./module/auth/sign-up/sign-up.module').then(m => m.SignUpModule),
        canActivate: [AuthService]
    },
    {
        path: 'forgot-password',
        loadChildren: () => import('./module/auth/forgotpassword/forgotpassword.module').then(m => m.ForgotpasswordModule),
        canActivate: [AuthService]
    },
    {
        path: 'reset-password',
        loadChildren: () => import('./module/auth/passowrd-update/passowrd-update.module').then(m => m.PassowrdUpdateModule),
        canActivate: [AuthService]
    },
    { path: 'borrow-history', loadChildren: () => import('./module/borrow/borrow-history/borrow-history.module').then(m => m.BorrowHistoryModule), canActivate: [authGuard] },
    { path: 'loan-request', loadChildren: () => import('./module/borrow/borrow-loan/borrow-loan.module').then(m => m.BorrowLoanModule), canActivate: [authGuard] },
    { path: 'borrow-detail/:id', loadChildren: () => import('./module/loan-detail/loan-detail.module').then(m => m.LoanDetailModule), canActivate: [authGuard] },
    { path: 'lending-history', loadChildren: () => import('./module/lend/lending-history/lending-history.module').then(m => m.LendingHistoryModule), canActivate: [authGuard] },
    { path: 'lending-detail/:id', loadChildren: () => import('./module/loan-detail/loan-detail.module').then(m => m.LoanDetailModule), canActivate: [authGuard] },
    { path: 'onboardassets', loadChildren: () => import('./module/onboardassets/onboardassets.module').then(m => m.OnboardassetsModule), canActivate: [authGuard] },
    { path: 'kyc', loadChildren: () => import('./module/kyc/kyc.module').then(m => m.KycModule), canActivate: [authGuard] },
    { path: 'nft-detail/:collection/:tokenId', loadChildren: () => import('./module/nft-detail/nft-detail.module').then(m => m.NftDetailModule), canActivate: [authGuard] },
    { path: 'lazy-mint/:id', loadChildren: () => import('./module/nft-detail/nft-detail.module').then(m => m.NftDetailModule), canActivate: [authGuard] },
    { path: 'kyc-verify/:status', loadChildren: () => import('./module/idenfy-redirection/idenfy-redirection.module').then(m => m.IdenfyRedirectionModule), canActivate: [authGuard] },
    { path: 'privacy-policy', loadChildren: () => import('./module/privacy/privacy.module').then(m => m.PrivacyModule) },
    { path: 'terms-and-conditions', loadChildren: () => import('./module/terms/terms.module').then(m => m.TermsModule) },
    { path: 'faq', loadChildren: () => import('./module/faq/faq.module').then(m => m.FaqModule) },
    { path: 'redeem-asset', loadChildren: () => import('./module/youritems/redeem-asset/redeem-asset.module').then(m => m.RedeemAssetModule), canActivate: [authGuard] },
    { path: 'verify-email', loadChildren: () => import('./module/custodymailverification/custodymailverification.module').then(m => m.CustodymailverificationModule) },
    { path: 'confirm-email', loadChildren: () => import('./module/custodymailverification/custodymailverification.module').then(m => m.CustodymailverificationModule) },
    { path: '**', loadChildren: () => import('./module/pagenotfound/pagenotfound.module').then(m => m.PagenotfoundModule), pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled',
        bindToComponentInputs: true
    })],
    exports: [RouterModule]
})
export class HomeNewRoutingModule {

}
