import { AfterContentChecked, ChangeDetectorRef, Component, ElementRef, HostListener, NgZone, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Idle } from '@ng-idle/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CommonService } from './shared/services/common.service';
import { environment } from 'src/environments/environment';
import { HeightService } from './shared/services/height.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, AfterContentChecked {
  @ViewChild('announcementContainer', { static: false }) announcementContainer: ElementRef | undefined;
  dynamicHeight: number = 0;
  @ViewChild('sessionExpiryModal', { static: false }) sessionExpiryModal?: ModalDirective;
  public isOnSignInPage!: boolean;
  public env = environment;
  public isGridView = false;
  public showoverlay = false;
  idleWarningTime = 0;
  confirmationData = '';
  private headerHeightSubscription!: Subscription;
  private resizeObserver: ResizeObserver | null = null;

  constructor(
    private commonservices: CommonService,
    private cdref: ChangeDetectorRef,
    private idle: Idle,
    private renderer: Renderer2,
    private heightService: HeightService,
    private ngZone: NgZone
  ) {
    this.idle.onIdleStart.subscribe(() => {
      this.sessionExpiryModal?.show();
    });
  }

  ngOnInit(): void {
    // Subscribe to header height changes
    this.headerHeightSubscription = this.heightService.headerHeight$.subscribe((headerHeight) => {
      this.updateElementTop(headerHeight);
    });
    this.setupResizeObserver();
    if (this.env.DISABLE_CONSOLE) {
      console.log = () => { };
    }
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleWarningTime = countdown;
      this.confirmationData =
        `Your session is going to expire in ${this.idleWarningTime} ${this.idleWarningTime > 1 ? 'seconds' : 'second'}.`
    })
    this.idle.onIdleEnd.subscribe(() => this.sessionExpiryModal?.hide());

    this.commonservices.showGridViewObservable.subscribe((response: boolean) => {
      this.isGridView = response;
    })
    this.commonservices.siginpageObservable.subscribe((response: boolean) => {
      this.isOnSignInPage = response;
    })
    if (this.env.production) {
      this.loadGoogleAnalytics();
    }
  }


  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }
  /**
   * Dynamically loads Google Analytics (GA) script based on the environment
   * configuration. It creates a new <script> tag and appends it to the <head>
   * after the <title> element. The script is loaded asynchronously and the
   * inline script is appended after the external script is loaded.
   *
   * @remarks
   * This function is only called when the environment is in production mode.
   */
  loadGoogleAnalytics() {
    const titleElement = document.querySelector('head title');
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${this.env.GOOGLE_ANALYTICS_KEY}`;
    script.async = true;
    document.head.appendChild(script);

    const inlineScript = document.createElement('script');
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${this.env.GOOGLE_ANALYTICS_KEY}');
    `;
    // Appending the external script tag just after the title
    if (titleElement) {
      titleElement.insertAdjacentElement('afterend', script);

      // Wait until the external script is appended, then append the inline script
      script.onload = () => {
        titleElement.insertAdjacentElement('afterend', inlineScript);
      };
    }
  }

  
  private updateElementTop(headerHeight: number) {
    const fixedOffset = window.innerWidth <= 1199 ? 65 : 73;
    const dynamicTopValue = headerHeight + fixedOffset;
    const element = document.querySelector('.commontopheight') as HTMLElement;
    if (element) {
      this.renderer.setStyle(element, 'top', `${dynamicTopValue}px`);
    }
  }

  private setupResizeObserver() {
    if ('ResizeObserver' in window) {
      this.resizeObserver = new ResizeObserver(() => {
        this.ngZone.run(() => {
          const currentHeight = this.heightService.getHeaderHeight();          
          this.updateElementTop(currentHeight);
        });
      });

      const element = document.querySelector('.commontopheight') as HTMLElement;
      if (element) {
        this.resizeObserver.observe(element);
      }
    }
  }

  private teardownResizeObserver() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  ngOnDestroy() {
    this.headerHeightSubscription.unsubscribe();
    this.teardownResizeObserver();
  }
}
